
import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component
export default class PwrBtn extends PwrVue {
  @Prop({ default: () => false }) link!: boolean | string;
  @Prop({ default: () => false }) secondary!: boolean | string;
  @Prop({ default: () => false }) small!: boolean | string;
  @Prop({ default: () => false }) block!: boolean | string;
  @Prop({ default: () => false }) borderless!: boolean | string;
  @Prop({ default: () => false }) flip!: boolean | string;
  @Prop({ default: () => false }) disabled!: boolean | string;
  @Prop({ default: () => false }) white!: boolean | string;
  @Prop() href!: string;

  $refs!: {
    linkTag: HTMLLinkElement;
  };

  private onClick(): void {
    if (!this.isProp(this.disabled)) {
      this.resetAfkTimer();

      if (this.href) {
        this.$refs.linkTag.click();
      }

      this.$emit('click');
    }
  }
}
