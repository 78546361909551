var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pwr-button",class:{
    'pwr-button-link': _vm.isProp(_vm.link),
    'pwr-button-secondary': _vm.isProp(_vm.secondary),
    'pwr-button-text': !_vm.isProp(_vm.small),
    'pwr-body1-text': _vm.isProp(_vm.small),
    'pwr-button-small': _vm.isProp(_vm.small),
    'pwr-button-block': _vm.isProp(_vm.block),
    'pwr-button-borderless': _vm.isProp(_vm.borderless),
    'pwr-button-disabled': _vm.isProp(_vm.disabled),
    'pwr-button-white': _vm.isProp(_vm.white)
  },on:{"click":_vm.onClick}},[_c('a',{ref:"linkTag",staticStyle:{"display":"none"},attrs:{"href":_vm.href}}),(_vm.isProp(_vm.link))?_c('div',{staticClass:"d-flex flex-row align-center justify-space-between"},[(_vm.isProp(_vm.secondary) && _vm.isProp(_vm.flip))?_c('span',{staticClass:"pwr-button-lin-secondary-arrow pwr-button-lin-secondary-arrow-flipped"}):_vm._e(),(!_vm.isProp(_vm.secondary) && _vm.isProp(_vm.flip))?_c('v-icon',{staticClass:"pa-0 ma-0 pwr-button-link-icon",attrs:{"dense":""}},[_vm._v("$chevronLeft ")]):_vm._e(),_vm._t("default"),(!_vm.isProp(_vm.secondary) && !_vm.isProp(_vm.flip))?_c('v-icon',{staticClass:"pa-0 ma-0 pwr-button-link-icon",attrs:{"dense":""}},[_vm._v("$chevronRight ")]):_vm._e(),(_vm.isProp(_vm.secondary) && !_vm.isProp(_vm.flip))?_c('span',{staticClass:"pwr-button-lin-secondary-arrow"}):_vm._e()],2):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }