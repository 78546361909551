
import { Component } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';
import OffersView from '@/views/Offers/OffersView.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';

@Component({
  components: {
    OffersView,
    PwrCardTitle
  }
})
export default class OffersForTeachersView extends PwrVue {
  private scope: number[] = [1, 2, 3];
}
