
import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component
export default class PwrCard extends PwrVue {
  @Prop({ default: () => false }) borderless!: boolean | string;

  get isBorderless() {
    if (typeof this.borderless === 'string') {
      return true;
    }

    return this.borderless;
  }
}
