
import { Component, Prop } from 'vue-property-decorator';
import PwrVue from '@/components/PwrVue';

@Component
export default class PwrCardTitle extends PwrVue {
  @Prop({ default: () => false }) secondary!: boolean | string;

  get isSecondary() {
    if (typeof this.secondary === 'string') {
      return true;
    }

    return this.secondary;
  }
}
